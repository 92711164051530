<template>
  <div>    
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="min-height: 475px;">
          <table id="tblPanelReportes" class="table table-hover">
            <thead>              
              <tr>
                <th style="width: 25px">#ID</th>
                <th style="width: 80px">Prioridad</th>
                <th style="width: 250px;">Fecha</th>
                <th>Tiempo estimado</th>
                <th style="width: 100px;">Estado</th>
                <th style="width: 200px;">Sesiones</th>
                <th style="width: 50px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ticket in tickets" :key="ticket.id">
                <td class="fw-semibold" style="width: 25px">
                  <router-link
                    :to="{
                      name: 'edicionTicketSoporte',
                      params: { id: ticket.id }
                    }"
                  >
                    {{ ticket.id }}
                  </router-link>
                </td>
                <td>
                  <span
                    class="badge font-size-13 badge-pill badge-soft-danger"
                    v-show="ticket.prioridad == 1"
                  >
                    URGENTE
                  </span>
                  <span
                    class="badge font-size-13 badge-pill badge-soft-warning"
                    v-show="ticket.prioridad == 2"
                  >
                    Media
                  </span>
                  <span
                    class="badge font-size-13 badge-pill badge-soft-success"
                    v-show="ticket.prioridad == 3"
                  >
                    Normal
                  </span>
                </td>
                <td>{{ formatoDate(ticket.fecha_programada, 'DD/MM/YYYY - hh:mm A') }}</td>
                <td>{{ convertidorSegundos_a_DHM(ticket.tiempo_estimado) }}</td>
                <td>
                  <span
                    class="badge font-size-12 bg-warning"
                    v-if="!ticket.completado && !ticket.cancelado"
                  >
                    Activo
                  </span>
                  <span
                    class="badge font-size-12 bg-primary"
                    v-if="ticket.completado && !ticket.cancelado"
                  >
                    Completado
                  </span>
                  <span
                    class="badge font-size-12 bg-danger"
                    v-if="ticket.cancelado"
                  >
                    Cancelado
                  </span>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'sesionesTicketSoporte',
                      params: { id: ticket.id }
                    }"
                    class="btn btn-sm btn-light"
                  >
                    <i class="mdi mdi-account-clock-outline"></i>
                    Sesión de soporte
                  </router-link>
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-show="ticket.mt_inicio == null && !ticket.completado && !ticket.cancelado">
                        <button class="dropdown-item success" @click="iniciarSoporte(ticket.id)">
                          <i class="mdi mdi-play-outline font-size-15 text-success"></i>
                          Iniciar soporte
                        </button>
                      </li>
                      <li v-show="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado && !ticket.cancelado">
                        <button
                          class="dropdown-item primary"
                          @click="!ticket.bpausa ? pausarSoporte(ticket) : reanudadSoporte(ticket.id)"
                        >
                          <i 
                            class="mdi font-size-15 text-primary"
                            :class="!ticket.bpausa ? 'mdi-pause' : 'mdi-play-outline'"
                          ></i>
                          {{ !ticket.bpausa ? 'Pausar' : 'Reanudad' }} soporte
                        </button>
                      </li>
                      <li v-show="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado && !ticket.cancelado">
                        <button class="dropdown-item dark" @click="finalizarSoporte(ticket.id)">
                          <i class="mdi mdi-stop font-size-15 text-dark"></i>
                          finalizar soporte
                        </button>
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'edicionTicketSoporte',
                            params: { id: ticket.id }
                          }"
                          class="dropdown-item"
                          :class="{
                            'warning': !ticket.completado && !ticket.cancelado,
                            'info': ticket.completado || ticket.cancelado
                          }"
                        >
                          <i 
                            class="mdi font-size-15"
                            :class="{
                              'mdi-square-edit-outline text-warning': !ticket.completado && !ticket.cancelado,
                              'mdi-eye text-info': ticket.completado || ticket.cancelado
                            }"
                          ></i>
                          {{ !ticket.completado && !ticket.cancelado ? 'Editar' : 'Visor' }}
                        </router-link>
                      </li>
                      <li v-show="!ticket.completado && !ticket.cancelado">
                        <button
                          class="dropdown-item danger"
                          @click="preguntaCancelarTicket(ticket)"
                        >
                          <i class="mdi mdi-cancel font-size-15 text-danger"></i>
                          Cancelar
                        </button>
                      </li>
                      <li v-show="!ticket.completado || ticket.cancelado">
                        <button
                          @click="preguntaEliminarTicket(ticket.id, ticket.nombre)"
                          class="dropdown-item danger"
                        >
                          <i class="mdi mdi-trash-can-outline font-size-15 text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarTickets()
        "
      ></paginador>
    </div>
  </div>
</template>

<script>
/**
 * Componente PANEL REPORTES
 */
import SesionTicketSoporteSrv from '@/services/SesionTicketSoporteSrv.js'
import Paginador from '@/components/Paginador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
import TicketSoporteSrv from '@/services/TicketSoporteSrv.js'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  name: 'CmpPanelReportes',
  props: {
    idCliente: {
      type: Number,
      required: true
    }
  },
  components: { Paginador },
  data() {
    return {
      tickets: [],
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.cmpPanelReportes.por_pagina') || 10),
      todosSeleccionados: false,
      total: 0,
      ultima_pagina: 0,

      columnas: {
        numero_cliente: true,
        fecha: true,
        tiempo_estimado: true,
        contacto: false
      },
    }
  },

  created: function() {
    var self = this

    this.refrescarTickets()

    iu.bus.on('nuevo-ticket-soporte-guardado', this.refrescarTickets)
    iu.bus.on('ticket-soporte-actualizado', this.refrescarTickets)

    // Si los tickets han sufrido algún cambio, entonces se manda a cargar los tickets nuevamente
    iu.bus.on('tickets-actualizados', this.refrescarTickets)

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },

  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.listadoClientes.tablero.cmpPanelRepostes.columnas', JSON.stringify(self.columnas))
    },
    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.listadoClientes.tablero.cmpPanelRepostes.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    formatoDate(mt, formato = 'DD/MM/YYYY') {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format(formato)
    },

    refrescarTickets: function() {
      var cmp = this

      iu.spinner.mostrar('#tblPanelReportes')
      
      let params = {
        pagina: cmp.pagina,
        por_pagina: parseInt(cmp.por_pagina),
        orden: 'desc'
      }

      ClienteSrv.ticketsSoporte(cmp.idCliente, params).then(response => {
        localStorage.setItem('argusblack.cmpPanelReportes.por_pagina', cmp.por_pagina)
        let res = response.data
        cmp.tickets = res.tickets
        cmp.total = res.total
        cmp.ultima_pagina = res.ultima_pagina
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los reportes del cliente'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblPanelReportes')
      })
    },
    cargarPagina: function(n) {
      this.pagina = n
      this.refrescarTickets()
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return dias+' dia '+horas+' hrs '+minutos+' min'
    },

    formatoFecha(fecha) {
      return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    formatoHora(fecha) {
      return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
    },
    palancaSeleccionTodos: function() {
      var self = this
      if (
        self.ticketsSeleccionados.length > 0 &&
        self.ticketsSeleccionados.length < self.tickets.length
      ) {
        self.ticketsSeleccionados = []
        self.seleccionarTodosTickets()

        self.todosSeleccionados = true
      } else if (self.ticketsSeleccionados.length == 0) {
        self.seleccionarTodosTickets()
        self.todosSeleccionados = true
      } else {
        self.ticketsSeleccionados = []
        self.todosSeleccionados = false
      }

      self.ticketsSeleccionadosActualizados()
    },
    seleccionarTodosTickets: function() {
      var self = this
      self.tickets.forEach(function(ticket, index) {
        self.ticketsSeleccionados.push(ticket.id)
      })
    },
    ticketsSeleccionadosActualizados: function() {
      var self = this

      // Desactiva el checkbox de selección de todos los tickets si la cantidad de tickets seleccionados es
      // menor que la cantidad de tickets
      if (
        self.todosSeleccionados &&
        self.ticketsSeleccionados.length < self.tickets.length
      ) {
        self.todosSeleccionados = false
      }

      iu.bus.emit( 'tickets-seleccionados-actualizados', this.ticketsSeleccionados )
    },



    iniciarSoporte: function(idTicket = null) {
      var self = this

      SesionTicketSoporteSrv.iniciar(idTicket).then(response => {
        iu.msg.success('Se inició correctamente la sesión de soporte')
        self.refrescarTickets()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo iniciar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    pausarSoporte: function(ticket) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Deseas pausar el ticket de soporte?",
        html: `¿Está seguro de pausar el ticket de soporte <strong>${ticket.id}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.motivoPausa(ticket)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    motivoPausa: function(ticket){
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Motivo de pausa del ticket de soporte",
        html: "Escriba el motivo de pausa del ticket de soporte:",
        icon: "question",
        input: "textarea",
        inputPlaceholder: "Escribe un motivo para pausar el soporte.",
        showCancelButton: true,
        confirmButtonText: "Pausar",
        cancelButtonText: "Cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
        allowOutsideClick: false
      }).then(text => {
        if (text.value) {
          let sesion = { motivo_pausa: text.value }

          SesionTicketSoporteSrv.pausar(ticket.id, sesion).then(response => {
            swal.fire("Pausado!", `La sesión de soporte se ha pausado correctamente, con motivo: <br><br> ${text.value}`, "success");
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo pausar el ticket de soporte '+ticket.id
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    reanudadSoporte: function(idTicket = null) {
      var self = this

      SesionTicketSoporteSrv.reanudar(idTicket).then(response => {
        iu.msg.success('Se a reanudado correctamente la sesión de soporte')
        self.refrescarTickets()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo reanudar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    finalizarSoporte: function(idTicket = null) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Deseas finalizar el ticket de soporte?",
        html: `¿Está seguro de finalizar el ticket de soporte <strong>${idTicket}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SesionTicketSoporteSrv.finalizar(idTicket).then(response => {
            swal.fire("Finalizado!", "La sesión de soporte se ha finalizado correctamente", "success");
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo finalizar el ticket de soporte '+idTicket
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    preguntaCancelarTicket: function(ticket) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: 'Cancelar ticket de soporte',
        html: '¿Desea cancelar el ticket de soporte del cliente <br> <strong>' + ticket.nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          TicketSoporteSrv.cancelar(ticket.id).then(response=>{
            swal.fire("Cancelado!", "Se a cancelado correctamente", "success")
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    preguntaEliminarTicket: function(idTicket, nombre) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: 'Eliminación de ticket de soporte',
        html: '¿Desea eliminar el ticket del cliente <br> <strong>' + nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          TicketSoporteSrv.eliminar(idTicket).then(response=>{
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },




  },
  mounted() {
    iu.spinner.mostrar('#tblPanelReportes')
  },
}
</script>

<style scoped>

</style>