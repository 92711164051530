<template>
  <div class="bx-pull-right">
    <router-link
      :to="{ 
        name: 'nuevoTicketSoporte',
        query: { id_cliente: idCliente }
      }"
      v-show="panelActivo == 'reportes'"
      class="btn btn-success bg-gradient btn-sm"
    >
      <span class="d-none d-sm-block">
        <i class="fas fa-plus"></i>
        Nuevo
      </span>
      <span class="d-block d-sm-none">
        <i class="fas fa-plus"></i>
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CmpOpcionesReportes',
  props: ['panelActivo', 'idCliente', 'nombreCliente', 'telefonosCliente']
}
</script>